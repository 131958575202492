import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyButton = lazy(
  () => import("./button-Bqu0ti_M.js").then((module) => ({
    default: module.Button
  }))
);
function Button({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyButton, { ...props });
}
const useButtonContent = () => {
  return useMemo(
    () => ({
      button: {
        component: Button
      }
    }),
    []
  );
};
export {
  useButtonContent
};
